<template>
  <loader-content v-if="loading" />

  <section v-else>
    <div class="d-flex align-center mb-6">
      <div class="text-h5 font-weight-bold mr-4">
        <span class="saira">Lista de Participantes</span>
      </div>

      <div class="text-body-2 subtext--text">
        {{ participants.length }} participantes
      </div>
    </div>

    <v-row v-if="participants.length > 0">
      <v-col v-for="(item, i) in participants" :key="i" cols="4" md="3" lg="2">
        <v-item>
          <v-card color="surface" class="pa-4" flat tile>
            <div class="px-4">
              <v-avatar class="mb-4" width="100%" height="100%" tile>
                <v-img
                  :src="
                    item.user.avatar || require('@/assets/utils/profile.png')
                  "
                  contain
                />
              </v-avatar>
            </div>

            <div class="text-center">
              <span class="text-body-1 font-weight-bold">
                {{ item.user.nickname }}
              </span>
            </div>
          </v-card>
        </v-item>
      </v-col>
    </v-row>

    <div v-else class="d-flex justify-center align-center">
      <span class="text-body-1 subtext--text">
        Nenhum resultado foi encontrado
      </span>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { getTeams } from "@/services/teams";
const moment = require("moment");

export default {
  name: "ParticipantsComponent",
  data() {
    return {
      loading: true,
      participants: [],
    };
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    ...mapState(["selected"]),
  },

  methods: {
    async getData() {
      try {
        const payload = {
          leagueId: this.selected.id,
        };

        await getTeams(payload).then((res) => {
          let items = [];

          res.forEach((e) => {
            items = [...items, ...e.team_players];
          });

          this.participants = items;
        });
      } catch (err) {
        // err
      } finally {
        this.loading = false;
      }
    },

    formatDate(value) {
      if (!value) return;
      return moment(value).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style></style>
